export const loadStyleSheet = (stylePath: string) => {
  const head = document.head
  const link = document.createElement('link')

  link.type = 'text/css'
  link.rel = 'stylesheet'
  link.href = stylePath

  head.appendChild(link)

  return () => {
    head.removeChild(link)
  }
}
