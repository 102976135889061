import React, { useEffect, useRef } from 'react'

import {
  WppAccordion,
  WppButton,
  WppDivider,
  WppFilterButton,
  WppIconDownload,
  WppIconSupport,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppTab,
  WppTabs,
  WppToggle,
  WppTopbar,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { ECharts, init } from 'echarts'

// import { PreviewHeader } from 'pages/preview/components/PreviewHeader/PreviewHeader'
import styles from './DataVisualizationPreview.module.scss'
import {
  dataVizPreviewAreaChartConfig,
  dataVizPreviewBarChartConfig,
  PAGE_PREVIEW_ID,
  pagePreviewNavigation,
} from '../consts'

export const DataVisualizationPreview = () => {
  const barChartRef = useRef<HTMLDivElement>(null)
  const areaChartRef = useRef<HTMLDivElement>(null)

  const initializeChart = (chartRef: any, option: any) => {
    let chart: ECharts | undefined

    if (chartRef.current !== null) {
      chart = init(chartRef.current, {})
    }

    if (chart) {
      chart.setOption(option)
    }
  }

  useEffect(() => {
    initializeChart(barChartRef, dataVizPreviewBarChartConfig)
    initializeChart(areaChartRef, dataVizPreviewAreaChartConfig)
  }, [])

  return (
    <div className={styles.root}>
      <div id={PAGE_PREVIEW_ID}>
        {/*<PreviewHeader breadcrumbs={pagePreviewBreadcrumbs} />*/}

        <WppTopbar
          value={pagePreviewNavigation[1].value}
          navigation={pagePreviewNavigation}
          className={styles.previewHeader}
        >
          <WppTypography type="m-strong" slot="app" style={{ marginRight: 44 }}>
            Marketingops
          </WppTypography>
        </WppTopbar>

        <div className={styles.main}>
          <div className={styles.titleWrapper}>
            <WppTypography type="3xl-heading">Branding</WppTypography>

            <div className={styles.buttonsWrapper}>
              <WppButton variant="secondary">Cancel</WppButton>
              <WppButton>Save & Continue</WppButton>
            </div>
          </div>

          <div className={styles.page}>
            <div className={styles.section}>
              <div className={styles.flex}>
                <WppTabs value="touchpoints" className={styles.tabs}>
                  <WppTab value="comms-task">Comms task</WppTab>
                  <WppTab value="audiences">Audiences</WppTab>
                  <WppTab value="touchpoints">Touchpoints</WppTab>
                </WppTabs>
              </div>

              <div className={styles.methodologyBlock}>
                <WppIconSupport />
                <WppTypography type="s-strong">Methodology</WppTypography>
              </div>
            </div>
            <WppDivider className={styles.divider} />

            <div className={styles.section} style={{ alignItems: 'flex-start', marginTop: 24 }}>
              <div className={styles.left}>
                <WppTypography type="s-body">
                  For each of your chosen audiences, please select the touchpoints you would like to use for them.
                </WppTypography>

                <WppAccordion text="Drive brand connection" expanded withDivider={false}>
                  <div className={styles.accordionContent}>
                    <WppToggle labelConfig={{ text: 'Professionals' }} checked required />
                  </div>
                </WppAccordion>
                <WppAccordion text="Build trust" expanded withDivider={false}>
                  <div className={styles.accordionContent}>
                    <WppToggle labelConfig={{ text: 'Individual entrepreneurs' }} checked required />
                    <WppToggle labelConfig={{ text: 'Students' }} required />
                  </div>
                </WppAccordion>
                <WppAccordion text="Prompt trial of brand/product" expanded withDivider={false}>
                  <div className={styles.accordionContent}>
                    <WppToggle labelConfig={{ text: 'Individual entrepreneurs' }} checked required />
                    <WppToggle labelConfig={{ text: 'Students' }} required />
                  </div>
                </WppAccordion>
              </div>

              <div className={styles.right}>
                <div className={styles.flex}>
                  <div className={styles.filtersWrapper}>
                    <WppSegmentedControl value="graph">
                      {[
                        { id: 'table', title: 'Table' },
                        { id: 'graph', title: 'Graph' },
                      ].map(tab => (
                        <WppSegmentedControlItem key={`item-${tab.id}`} value={tab.id}>
                          {tab.title}
                        </WppSegmentedControlItem>
                      ))}
                    </WppSegmentedControl>
                    <WppFilterButton>Filters</WppFilterButton>
                    <WppToggle labelConfig={{ text: 'Show selected touchpoints only' }} />
                  </div>

                  <WppButton variant="secondary">
                    <WppIconDownload slot="icon-start" />
                    Export graph
                  </WppButton>
                </div>
                <div>
                  <div ref={barChartRef} style={{ width: '825px', height: '500px' }} />
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.page} ${styles.graph}`}>
            <div className={styles.flex}>
              <WppTypography type="l-strong">Income Distribution</WppTypography>
              <WppButton variant="secondary">
                <WppIconDownload slot="icon-start" />
                Export graph
              </WppButton>
            </div>
            <div>
              <div ref={areaChartRef} style={{ width: '100%', height: '500px' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
