import React from 'react'

import {
  WppActionButton,
  WppButton,
  WppDivider,
  WppFilterButton,
  WppIconEdit,
  WppIconMore,
  WppIconPlus,
  WppInput,
  WppPagination,
  WppTab,
  WppTabs,
  WppTopbar,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import '@platform-ui-kit/components-library/dist/collection/ag-theme-wpp.css'

import styles from './TablePreview.module.scss'
import {
  PAGE_PREVIEW_ID,
  pagePreviewNavigation,
  tablePreviewColumnDefs,
  tablePreviewTableDefaultColConfig,
  tablePreviewTableDisplayData,
} from '../consts'

export const TablePreview = () => (
  <div className={styles.root}>
    <div id={PAGE_PREVIEW_ID}>
      <WppTopbar
        value={pagePreviewNavigation[1].value}
        navigation={pagePreviewNavigation}
        className={styles.previewHeader}
      >
        <WppTypography type="m-strong" slot="app" style={{ marginRight: 44 }}>
          Marketingops
        </WppTypography>
      </WppTopbar>

      <div className={styles.main}>
        <div className={styles.titleWrapper}>
          <WppTypography type="3xl-heading">Creative optimisation</WppTypography>

          <div className={styles.buttonsWrapper}>
            <WppButton variant="secondary">
              <WppIconMore direction="horizontal" slot="icon-start" />
            </WppButton>
            <WppButton>
              <WppIconEdit slot="icon-start" />
              Edit project
            </WppButton>
          </div>
        </div>

        <div className={styles.wrapper}>
          <WppTabs value="accounts" className={styles.tabs}>
            <WppTab value="overview">Overview</WppTab>
            <WppTab value="canvas">Canvas</WppTab>
            <WppTab value="accounts">Accounts</WppTab>
            <WppTab value="activity-list">Activity list</WppTab>
            <WppTab value="request">Request</WppTab>
          </WppTabs>
          <WppDivider className={styles.divider} />

          <div className={styles.page}>
            <div className={styles.flex} style={{ marginBottom: 24 }}>
              <div className={styles.left}>
                <WppInput type="search" placeholder="Text" size="s" />
                <WppFilterButton>Filter</WppFilterButton>
              </div>
              <div className={styles.right}>
                <WppActionButton>
                  <WppIconPlus slot="icon-start" />
                  Download XLS
                </WppActionButton>
                <WppButton variant="secondary" size="s">
                  <WppIconPlus slot="icon-start" />
                  Add account
                </WppButton>
              </div>
            </div>

            <div className="ag-theme-wpp" style={{ height: '526px', width: '100%' }}>
              <AgGridReact
                rowData={tablePreviewTableDisplayData}
                columnDefs={tablePreviewColumnDefs}
                defaultColDef={tablePreviewTableDefaultColConfig}
                rowSelection="multiple"
                rowDragMultiRow
                animateRows
                rowDragManaged
                pagination
                paginationPageSize={10}
                suppressPaginationPanel
                domLayout="normal"
              />
            </div>
            <WppPagination
              count={tablePreviewTableDisplayData.length}
              selectedItemPerPage={10}
              itemsPerPage={[3, 5, 10]}
              activePageNumber={1}
              pageSelectThreshold={9}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)
