import React from 'react'

import {
  WppActionButton,
  WppAvatar,
  WppIconMore,
  WppIconNavigationMenu,
  WppIconNotificationOn,
  WppIconSearch,
  WppTopbar,
  WppDivider,
  WppTypography,
  WppCard,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppListItem,
  WppIconChevron,
} from '@platform-ui-kit/components-library-react'
import logoUrl from 'assets/wpp-logo.svg'

import { brands, links, workItems } from './consts'
import styles from './HomePreview.module.scss'
import { PAGE_PREVIEW_ID } from '../consts'

export const HomePreview: React.FC = () => {
  return (
    <div className={styles.homePreview} id={PAGE_PREVIEW_ID}>
      <WppTopbar className={styles.topbar} navigation={[]}>
        <div slot="app" className={styles.app}>
          <WppIconNavigationMenu />
          <img src={logoUrl} alt="logo" className={styles.logo} />
        </div>
        <div slot="right" className={styles.actions}>
          <WppActionButton className={styles.helpButton} variant="secondary">
            <WppIconSearch slot="icon-start" className={styles.searchIcon} />
            Help
          </WppActionButton>
          <WppIconMore direction="horizontal" />
          <WppActionButton className={styles.notification} variant="secondary">
            <WppIconNotificationOn slot="icon-start" className={styles.notificationIcon} />
          </WppActionButton>
          <WppAvatar name="Raul Paul" />
        </div>
      </WppTopbar>
      <WppDivider />
      <div className={styles.body}>
        <div className={styles.linksAndBrandBlock}>
          <WppCard size="l" className={styles.brandCard}>
            <WppSegmentedControl value="brands" className={styles.segmentedControl} size="s">
              <WppSegmentedControlItem value="brands">Brands</WppSegmentedControlItem>
              <WppSegmentedControlItem value="apps">Apps</WppSegmentedControlItem>
            </WppSegmentedControl>
            {brands.map(brand => (
              <WppListItem className={styles.listItem}>
                <p slot="label">{brand.name}</p>
                <WppAvatar src={brand.src} size="xs" slot="left" />
              </WppListItem>
            ))}
          </WppCard>
          <WppCard size="l" className={styles.linkCard}>
            <WppTypography type="l-strong" slot="header" className={styles.header}>
              Favourite links
            </WppTypography>
            <WppActionButton variant="secondary" slot="actions">
              View all
            </WppActionButton>
            {links.map(link => (
              <WppListItem className={styles.listItem}>
                <p slot="label">{link.name}</p>
                <WppAvatar src={link.src} size="xs" slot="left" />
              </WppListItem>
            ))}
          </WppCard>
        </div>
        <WppCard size="l" className={styles.workCard}>
          <WppTypography type="l-strong" slot="header" className={styles.header}>
            Resent work
          </WppTypography>
          {workItems.map(workItem => (
            <div className={styles.workItem}>
              <div className={styles.description}>
                <WppTypography type="s-midi">{workItem.title}</WppTypography>
                <WppTypography type="s-body">{workItem.description}</WppTypography>
                <WppTypography type="xs-body" className={styles.breadcrumb}>
                  {workItem.breadCrumb}
                </WppTypography>
              </div>
              <WppIconChevron direction="right" />
            </div>
          ))}
        </WppCard>
        <div className={styles.cardsImages}>
          <div className={styles.topCardsImages}>
            <WppCard size="m" interactive className={styles.cannesCard}>
              <WppTypography type="xl-heading" className={styles.title}>
                WPP wins industry’s most creative company at Cannes Lions 2022
              </WppTypography>
            </WppCard>
            <div className={styles.pragueAndHumanCards}>
              <WppCard size="m" interactive className={styles.pragueCard}>
                <WppTypography type="m-strong" className={styles.title}>
                  WPP opens its new Prague Campus
                </WppTypography>
              </WppCard>
              <WppCard size="m" interactive className={styles.humanCard}>
                <WppTypography type="m-strong" className={styles.title}>
                  Human Rights Campaign launches huge...
                </WppTypography>
              </WppCard>
            </div>
          </div>
          <div className={styles.bottomCardsImages}>
            <WppCard size="m" interactive className={styles.talentCard}>
              <WppTypography type="m-strong" className={styles.title}>
                WPP appoints LJ Louis as Global Chief Talent...
              </WppTypography>
            </WppCard>
            <WppCard size="m" interactive className={styles.womenCard}>
              <WppTypography type="m-strong" className={styles.title}>
                Why brands need to get real about older women
              </WppTypography>
            </WppCard>
            <WppCard size="m" interactive className={styles.techCard}>
              <WppTypography type="xl-heading" className={styles.title}>
                WPP acquires leading AI technology company Satalia
              </WppTypography>
            </WppCard>
          </div>
        </div>
      </div>
    </div>
  )
}
