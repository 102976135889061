import eucerinLogo from 'assets/brands/eucerin.svg'
import generalElectricLogo from 'assets/brands/general electric.svg'
import johnsonLogo from 'assets/brands/johnson.svg'
import nestleLogo from 'assets/brands/nestle.svg'
import niveaLogo from 'assets/brands/nivea.svg'
import pepsiLogo from 'assets/brands/pepsi.svg'
import protectorLogo from 'assets/brands/protector.svg'
import skinLogo from 'assets/brands/skin.svg'
import hubspot from 'assets/links/hubspot.svg'
import intercom from 'assets/links/intercom.svg'
import looker from 'assets/links/looker.svg'
import optimizely from 'assets/links/optimizely.svg'
import salesforce from 'assets/links/salesforce.svg'

export const brands = [
  {
    name: 'Nivea',
    src: niveaLogo,
  },
  {
    name: 'General electric',
    src: generalElectricLogo,
  },
  {
    name: 'Procter & Gamble',
    src: protectorLogo,
  },
  {
    name: 'Johnson & Johnson',
    src: johnsonLogo,
  },
  {
    name: 'Eucerin',
    src: eucerinLogo,
  },
  {
    name: 'Nestle',
    src: nestleLogo,
  },
  {
    name: 'PepsiCo',
    src: pepsiLogo,
  },
  {
    name: 'Skin Stories',
    src: skinLogo,
  },
]

export const links = [
  {
    name: 'Hubspot',
    src: hubspot,
  },
  {
    name: 'Intercom',
    src: intercom,
  },
  {
    name: 'Looker',
    src: looker,
  },
  {
    name: 'Optimizely',
    src: optimizely,
  },
  {
    name: 'Salesforce',
    src: salesforce,
  },
  {
    name: 'Kuphal Group',
    src: '',
  },
  {
    name: 'Weber',
    src: '',
  },
  {
    name: 'Turner Brothers',
    src: '',
  },
]

export const workItems = [
  {
    title: 'Maximise Audiences',
    description: 'Christmas Youth 2022',
    breadCrumb: 'Unilever / Germany / Diet Coke',
  },
  {
    title: 'Campaign Monitor',
    description: 'Christmas Party 2022',
    breadCrumb: 'BT / Germany / EE',
  },
  {
    title: 'Maximise Campaigns',
    description: 'Campaign | Chr...German Market',
    breadCrumb: 'Unilever / Germany / Diet Coke',
  },
  {
    title: 'Maximise Campaigns',
    description: 'Italy Giro 2022',
    breadCrumb: 'Ferrero / Germany / Nutella',
  },
  {
    title: 'Architect Touchpoints ',
    description: 'Italy Giro 2022 - Naples',
    breadCrumb: 'Ferrero / Germany / Nutella',
  },
  {
    title: 'Media Planning',
    description: 'Promotion | EU Market',
    breadCrumb: 'Beiersdorf / Europe / Eucerin',
  },
  {
    title: 'Vision Settings',
    description: 'Campaign | Chr...German Market',
    breadCrumb: 'Unilever / Germany / Diet Coke',
  },
  {
    title: 'Maximise Campaigns',
    description: 'Campaign | US Market',
    breadCrumb: 'BT / USA / EE',
  },
]
