import React from 'react'

import {
  WppIconAsk,
  WppIconMore,
  WppIconSort,
  WppIconTick,
  WppTag,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { ColDef } from 'ag-grid-community'

import styles from '../pages/TablePreview/TablePreview.module.scss'

export const PAGE_PREVIEW_ID = 'theme-builder-preview'

export const pagePreviewTabs = [
  { id: 'home', title: 'Home screen' },
  { id: 'form', title: 'Form' },
  { id: 'table', title: 'Table' },
  { id: 'data-viz', title: 'Data Visualization' },
  { id: 'components', title: 'Components' },
]

export const pagePreviewBreadcrumbs = [
  { label: 'ACME' },
  { label: 'US' },
  { label: 'Umbrella' },
  { label: 'Targeting APP' },
]

export const tablePreviewColumnDefs: ColDef[] = [
  {
    field: 'account',
    sortable: true,
    resizable: true,
    rowDrag: true,
    width: 320,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellRenderer: ({ data }: { data: any }) => (
      <div className={styles.flexColumn}>
        <WppTypography type="s-body">{data.account}</WppTypography>
        <WppTypography type="xs-body" style={{ color: 'grey' }}>
          {data.accountNumber}
        </WppTypography>
      </div>
    ),
  },
  {
    field: 'status',
    sortable: true,
    width: 100,
    cellRenderer: ({ data }: { data: any }) => <WppTag label={data.status} variant={data.status} />,
  },
  {
    field: 'agency',
    sortable: true,
    width: 120,
  },
  {
    field: 'market',
    sortable: true,
    width: 120,
    cellRenderer: ({ data }: { data: any }) => data.market && <WppTag label={data.market} variant="neutral" />,
  },
  {
    field: 'client',
    sortable: true,
    width: 100,
  },
  {
    field: 'mappedBy',
    sortable: true,
    width: 120,
  },
  {
    field: 'date',
    sortable: true,
    width: 110,
  },
  {
    field: 'dashboard',
    width: 140,
    cellRenderer: ({ data }: { data: any }) => data.dashboard && <WppIconTick />,
  },
  {
    field: 'more',
    width: 60,
    cellRenderer: () => <WppIconMore direction="horizontal" style={{ color: 'var(--wpp-primary-color-500)' }} />,
  },
]

export const tablePreviewTableDefaultColConfig = {
  headerComponent: (props: any) => (
    <div className="ag-header-cell-comp">
      {props.displayName !== 'More' && <WppTypography type="s-strong">{props.displayName}</WppTypography>}
      {props.displayName === 'Dashboard' && <WppIconAsk style={{ marginLeft: 8 }} />}
      {props.enableSorting && (
        <div className="ag-sorting">
          <WppIconSort
            className={`top-sort-icon ${
              props.column.isSortAscending() ? 'active' : props.column.isSortNone() ? '' : 'not-active'
            }`}
          />
        </div>
      )}
    </div>
  ),
  sortable: false,
  cellRenderer: ({ value }: ColDef['cellRenderer']) => <WppTypography type="s-body">{value}</WppTypography>,
}

export const tablePreviewTableInitialData = [
  {
    id: 1,
    account: 'BMW Germany / SUV / Commer',
    accountNumber: '123-423-4532',
    status: 'positive',
    statusText: 'Mapped',
    agency: 'Wavemaker',
    market: 'GE',
    client: 'BMW',
    mappedBy: 'Felicia Wood',
    date: '2022-05-11',
    dashboard: true,
  },
  {
    id: 2,
    account: 'Ford / Kuga / Commerce / Direct',
    accountNumber: '523-145-2355',
    status: 'negative',
    statusText: 'Inactive',
    agency: 'Mindshare',
    market: 'IN',
    client: 'Ford',
    mappedBy: 'Travis Sullivan',
    date: '2022-05-13',
    dashboard: false,
  },
  {
    id: 3,
    account: 'BMW Germany / SUV / x5 / Direct',
    accountNumber: '123-423-8732',
    status: 'neutral',
    statusText: 'Disabled',
    agency: 'Essence',
    market: 'GE',
    client: 'BMW',
    mappedBy: 'Jessie Knight',
    date: '2022-05-10',
    dashboard: true,
  },
  {
    id: 4,
    account: 'Citroën',
    accountNumber: '873-643-3943',
    status: 'warning',
    statusText: 'Unmapped',
    agency: undefined,
    market: undefined,
    client: undefined,
    mappedBy: undefined,
    date: undefined,
    dashboard: false,
  },
]

export const tablePreviewTableDisplayData = [...Array(83)].map(
  (_, i) => tablePreviewTableInitialData[i % tablePreviewTableInitialData.length],
)

export const pagePreviewNavigation: any[] = [
  {
    label: 'Client services',
    value: 'client-services',
  },
  {
    label: 'Learning',
    value: 'learning',
    children: [
      {
        label: 'learning 1',
        value: 'learning-1',
      },
      {
        label: 'learning 2',
        value: 'learning-2',
      },
    ],
  },
  {
    label: 'Marketplace',
    value: 'marketplace',
  },
  {
    label: 'Reporting',
    value: 'reporting',
  },
  {
    label: 'Dev portal',
    value: 'dev-portal',
  },
]

export const formPreviewNavigation: any[] = [
  {
    label: 'Catalogue',
    value: 'catalogue',
  },
  {
    label: 'Favourites',
    value: 'favourites',
  },
  {
    label: 'My agency',
    value: 'my-agency',
  },
  {
    label: 'Content management',
    value: 'content-management',
  },
]

export const dataVizPreviewBarChartConfig = {
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  xAxis: {
    type: 'value',
  },
  yAxis: {
    type: 'category',
    data: [
      'TV Ads',
      'Seeing product/packaging',
      'Recommendation',
      'TV Sponsorship',
      'Social media brand page',
      'Social media brand page',
    ],
  },
  series: [
    {
      data: [
        {
          value: 231,
          itemStyle: { color: '#BC71FB' },
          label: {
            show: true,
            position: 'right',
          },
        },
        {
          value: 157,
          itemStyle: { color: '#7B90DF' },
          label: {
            show: true,
            position: 'right',
          },
        },
        {
          value: 108,
          itemStyle: { color: '#F48052' },
          label: {
            show: true,
            position: 'right',
          },
        },
        {
          value: 138,
          itemStyle: { color: '#7B90DF' },
          label: {
            show: true,
            position: 'right',
          },
        },
        {
          value: 187,
          itemStyle: { color: '#68A512' },
          label: {
            show: true,
            position: 'right',
          },
        },
        {
          value: 146,
          itemStyle: { color: '#F48052' },
          label: {
            show: true,
            position: 'right',
          },
        },
      ],
      type: 'bar',
    },
  ],
}

export const dataVizPreviewAreaChartConfig = {
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  xAxis: [
    {
      type: 'category',
      boundaryGap: false,
      data: ['0', '500', '1000', '1500', '2000', '2500', '3000'],
    },
  ],
  yAxis: [
    {
      type: 'value',
    },
  ],
  series: [
    {
      name: 'Email',
      type: 'line',
      stack: 'Total',
      color: '#F3E6FE',
      areaStyle: {
        color: '#F3E6FE',
      },
      emphasis: {
        focus: 'series',
      },
      data: [120, 132, 101, 134, 90, 230, 210],
    },
    {
      name: 'Union Ads',
      type: 'line',
      stack: 'Total',
      color: '#E6C9FD',
      areaStyle: {
        color: '#E6C9FD',
      },
      emphasis: {
        focus: 'series',
      },
      data: [220, 182, 191, 234, 290, 330, 310],
    },
    {
      name: 'Video Ads',
      type: 'line',
      stack: 'Total',
      color: '#CE97FB',
      areaStyle: {
        color: '#CE97FB',
      },
      emphasis: {
        focus: 'series',
      },
      data: [150, 232, 201, 154, 190, 330, 410],
    },
    {
      name: 'Direct',
      type: 'line',
      stack: 'Total',
      color: '#9E30F8',
      areaStyle: {
        color: '#9E30F8',
      },
      emphasis: {
        focus: 'series',
      },
      data: [320, 332, 301, 334, 390, 330, 320],
    },
    {
      name: 'Search Engine',
      type: 'line',
      stack: 'Total',
      color: '#4E058A',
      areaStyle: {
        color: '#4E058A',
      },
      emphasis: {
        focus: 'series',
      },
      data: [820, 932, 901, 934, 1290, 1330, 1320],
    },
  ],
}
