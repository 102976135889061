import React from 'react'

import { RangeOf } from '@platform-ui-kit/components-library/dist/types/types/numberRange'
import {
  WppActionButton,
  WppBackToTopButton,
  WppBanner,
  WppButton,
  WppCard,
  WppCheckbox,
  WppDivider,
  WppFilterButton,
  WppFloatingButton,
  WppIconButton,
  WppIconFavorites,
  WppIconLockOn,
  WppIconMore,
  WppIconPlus,
  WppIconSmile,
  WppInlineMessage,
  WppInput,
  WppListItem,
  WppPill,
  WppTabs,
  WppTag,
  WppToast,
  WppTab,
  WppTypography,
  WppCardGroup,
  WppIconDocument,
} from '@platform-ui-kit/components-library-react'

import { CardPreview } from './components/CardPreview'
import styles from './ComponentsPreview.module.scss'

export const ComponentsPreview = () => (
  <div className={styles.root}>
    <div id="theme-builder-preview" className={styles.wrapper}>
      <WppTypography type="4xl-display" className={styles.title}>
        Components
      </WppTypography>

      <div className={styles.main}>
        <div className={styles.section} style={{ marginBottom: 0 }}>
          <WppTypography type="xl-heading" className={styles.sectionLabel}>
            Buttons
          </WppTypography>

          <div className={styles.subSection}>
            <div className={styles.block}>
              <WppTypography type="2xs-strong" className={styles.blockLabel}>
                Primary regular button
              </WppTypography>

              <div className={styles.componentsList}>
                <WppButton>Button</WppButton>
                <WppButton className={styles.primaryButtonHover}>Button</WppButton>
                <WppButton className={styles.primaryButtonActive}>Button</WppButton>
                <WppButton disabled>Button</WppButton>
              </div>
            </div>

            <div className={styles.block}>
              <WppTypography type="2xs-strong" className={styles.blockLabel}>
                Secondary regular button
              </WppTypography>

              <div className={styles.componentsList}>
                <WppButton variant="secondary">Button</WppButton>
                <WppButton variant="secondary" className={styles.secondaryButtonHover}>
                  Button
                </WppButton>
                <WppButton variant="secondary" className={styles.secondaryButtonActive}>
                  Button
                </WppButton>
                <WppButton variant="secondary" disabled>
                  Button
                </WppButton>
              </div>
            </div>
          </div>

          <div className={styles.subSection}>
            <div className={styles.block}>
              <WppTypography type="2xs-strong" className={styles.blockLabel}>
                Primary Action button
              </WppTypography>

              <div className={styles.componentsList}>
                <div>
                  <WppActionButton>
                    <WppIconPlus slot="icon-start" />
                    Action
                  </WppActionButton>
                </div>
                <div className={styles.primaryActionButtonHover}>
                  <WppActionButton>
                    <WppIconPlus slot="icon-start" />
                    Action
                  </WppActionButton>
                </div>
                <div className={styles.primaryActionButtonActive}>
                  <WppActionButton>
                    <WppIconPlus slot="icon-start" />
                    Action
                  </WppActionButton>
                </div>
                <div>
                  <WppActionButton disabled>
                    <WppIconPlus slot="icon-start" />
                    Action
                  </WppActionButton>
                </div>
              </div>
            </div>

            <div className={styles.block}>
              <WppTypography type="2xs-strong" className={styles.blockLabel}>
                Secondary action button
              </WppTypography>

              <div className={styles.componentsList}>
                <div>
                  <WppActionButton variant="secondary">
                    <WppIconPlus slot="icon-start" />
                    Action
                  </WppActionButton>
                </div>
                <div className={styles.secondaryActionButtonHover}>
                  <WppActionButton variant="secondary">
                    <WppIconPlus slot="icon-start" />
                    Action
                  </WppActionButton>
                </div>
                <div className={styles.secondaryActionButtonActive}>
                  <WppActionButton variant="secondary">
                    <WppIconPlus slot="icon-start" />
                    Action
                  </WppActionButton>
                </div>
                <div>
                  <WppActionButton variant="secondary" disabled>
                    <WppIconPlus slot="icon-start" />
                    Action
                  </WppActionButton>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.subSection}>
            <div className={styles.block}>
              <WppTypography type="2xs-strong" className={styles.blockLabel}>
                Filter button
              </WppTypography>

              <div className={styles.componentsList}>
                <WppFilterButton>Filter</WppFilterButton>
                <WppFilterButton className={styles.filterButtonHover}>Filter</WppFilterButton>
                <WppFilterButton className={styles.filterButtonActive}>Filter</WppFilterButton>
                <WppFilterButton disabled>Filter</WppFilterButton>
              </div>
            </div>

            <div className={styles.block}>
              <WppTypography type="2xs-strong" className={styles.blockLabel}>
                More button
              </WppTypography>

              <div className={styles.componentsList}>
                <WppIconButton>
                  <WppIconMore direction="horizontal" />
                </WppIconButton>
                <WppIconButton className={styles.iconButtonHover}>
                  <WppIconMore direction="horizontal" />
                </WppIconButton>
                <WppIconButton className={styles.iconButtonActive}>
                  <WppIconMore direction="horizontal" />
                </WppIconButton>
                <WppIconButton disabled>
                  <WppIconMore direction="horizontal" />
                </WppIconButton>
              </div>
            </div>
          </div>

          <div className={styles.subSection}>
            <div className={styles.block}>
              <WppTypography type="2xs-strong" className={styles.blockLabel}>
                Floating action button
              </WppTypography>

              <div className={styles.componentsList}>
                <WppFloatingButton />
                <WppFloatingButton className={styles.floatingButtonHover} />
                <WppFloatingButton className={styles.floatingButtonActive} />
                <WppFloatingButton disabled />
              </div>
            </div>

            <div className={styles.block}>
              <WppTypography type="2xs-strong" className={styles.blockLabel}>
                Back to top button
              </WppTypography>

              <div className={styles.componentsList}>
                <WppBackToTopButton />
                <WppBackToTopButton className={styles.backToTopButtonHover} />
                <WppBackToTopButton className={styles.backToTopButtonActive} />
                <div />
              </div>
            </div>
          </div>
        </div>
        <WppDivider className={styles.divider} />

        <div className={styles.section} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 0 }}>
          <div className={styles.block}>
            <WppTypography type="xl-heading" className={styles.sectionLabel}>
              List
            </WppTypography>

            <div className={styles.subSection}>
              <div className={styles.block}>
                <WppTypography type="2xs-strong" className={styles.blockLabel}>
                  Single selection
                </WppTypography>

                <div className={styles.card}>
                  <WppListItem className={styles.listItem}>
                    <WppIconDocument slot="left" />
                    <p slot="label">Idle</p>
                  </WppListItem>
                  <WppListItem className={`${styles.listItem} ${styles.listItemHover}`}>
                    <WppIconDocument slot="left" style={{ color: 'var(--wpp-icon-color-hover)' }} />
                    <p slot="label">Hover</p>
                  </WppListItem>
                  <WppListItem className={`${styles.listItem} ${styles.listItemActive}`}>
                    <WppIconDocument slot="left" style={{ color: 'var(--wpp-icon-color-active)' }} />
                    <p slot="label">Pressed</p>
                  </WppListItem>
                  <WppListItem checked className={styles.listItem}>
                    <WppIconDocument slot="left" />
                    <p slot="label">Selected</p>
                  </WppListItem>
                  <WppListItem disabled>
                    <WppIconDocument slot="left" />
                    <p slot="label">Disabled</p>
                  </WppListItem>
                  <WppListItem className={styles.listItem}>
                    <WppIconDocument slot="left" />
                    <p slot="label">Idle</p>
                  </WppListItem>
                  <WppListItem className={styles.listItem}>
                    <WppIconDocument slot="left" />
                    <p slot="label">Idle</p>
                  </WppListItem>
                </div>
              </div>

              <div className={styles.block}>
                <WppTypography type="2xs-strong" className={styles.blockLabel}>
                  Multiple selection
                </WppTypography>

                <div className={styles.card}>
                  <WppListItem multiple className={styles.listItem}>
                    <p slot="label">Idle</p>
                  </WppListItem>
                  <WppListItem multiple className={`${styles.listItem} ${styles.listItemHover}`}>
                    <p slot="label">Hover</p>
                  </WppListItem>
                  <WppListItem multiple className={`${styles.listItem} ${styles.listItemActive}`}>
                    <p slot="label">Pressed</p>
                  </WppListItem>
                  <WppListItem multiple checked className={styles.listItem}>
                    <p slot="label">Selected Idle</p>
                  </WppListItem>
                  <div className={styles.listItemSelectedHover}>
                    <WppListItem multiple checked className={styles.listItem}>
                      <p slot="label">Selected Hover</p>
                    </WppListItem>
                  </div>
                  <div className={styles.listItemSelectedActive}>
                    <WppListItem multiple checked className={styles.listItem}>
                      <p slot="label">Selected Pressed</p>
                    </WppListItem>
                  </div>
                  <WppListItem multiple disabled className={styles.listItem}>
                    <p slot="label">Disabled</p>
                  </WppListItem>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.block}>
            <WppTypography type="xl-heading" className={styles.sectionLabel}>
              Tag
            </WppTypography>

            <div className={styles.subSection} style={{ justifyContent: 'flex-start', marginBottom: 24 }}>
              <WppTag label="Neutral" variant="neutral" style={{ marginRight: 8 }} />
              <WppTag label="Positive" variant="positive" style={{ marginRight: 8 }} />
              <WppTag label="Negative" variant="negative" style={{ marginRight: 8 }} />
              <WppTag label="Warnings" variant="warning" style={{ marginRight: 8 }} />
            </div>
            <div className={styles.subSection} style={{ flexWrap: 'wrap', justifyContent: 'flex-start' }}>
              {[...Array(9)].map((_, i) => (
                <WppTag
                  key={`tag-cat-${i}`}
                  label={`Cat-${i + 1}`}
                  categoricalColorIndex={(i + 1) as RangeOf<9>}
                  style={{ marginRight: 8, marginBottom: 8 }}
                >
                  <WppIconSmile slot="icon-start" />
                </WppTag>
              ))}
            </div>
          </div>
        </div>
        <WppDivider className={styles.divider} />

        <div className={styles.section}>
          <WppTypography type="xl-heading" className={styles.sectionLabel}>
            Banner
          </WppTypography>

          <div style={{ marginBottom: 16 }}>
            <WppBanner show type="information" closable>
              Info message
            </WppBanner>
          </div>
          <WppBanner show type="warning" closable>
            Highlight message
          </WppBanner>
        </div>
        <WppDivider className={styles.divider} />

        <div className={styles.section}>
          <WppTypography type="xl-heading" className={styles.sectionLabel}>
            Inline Message
          </WppTypography>

          <div className={styles.subSection}>
            <WppInlineMessage size="m" type="warning" message="Warning message" />
            <WppInlineMessage size="m" type="error" message="Error message" />
            <WppInlineMessage size="m" type="success" message="Success message" />
            <WppInlineMessage size="m" type="information" message="Information message" />
          </div>
        </div>
        <WppDivider className={styles.divider} />

        <div className={styles.section}>
          <WppTypography type="xl-heading" className={styles.sectionLabel}>
            Toast Message
          </WppTypography>

          <div className={styles.subSection} style={{ flexWrap: 'wrap', justifyContent: 'flex-start' }}>
            <WppToast type="warning" message="Warning message" duration={60000} style={{ marginRight: 8 }} />
            <WppToast type="error" message="Error message" duration={60000} style={{ marginRight: 8 }} />
            <WppToast type="success" message="Success message" duration={60000} style={{ marginRight: 8 }} />
            <WppToast type="information" message="Information message" duration={60000} style={{ marginRight: 8 }} />
          </div>
        </div>
        <WppDivider className={styles.divider} />

        <div className={styles.section}>
          <WppTypography type="xl-heading" className={styles.sectionLabel}>
            Tabs
          </WppTypography>

          <WppTabs value="selected">
            <WppTab value="idle" counter={2}>
              Idle
            </WppTab>
            <WppTab value="hover" counter={2} className={styles.tabHover}>
              Hover
            </WppTab>
            <WppTab value="pressed" counter={2} className={styles.tabActive}>
              Pressed
            </WppTab>
            <WppTab value="selected" counter={2}>
              Selected
            </WppTab>
            <WppTab value="idle1" counter={2}>
              Idle
            </WppTab>
            <WppTab value="idle2" counter={2}>
              Idle
            </WppTab>
          </WppTabs>
        </div>
        <WppDivider className={styles.divider} />

        <div className={styles.section}>
          <WppTypography type="xl-heading" className={styles.sectionLabel}>
            Input
          </WppTypography>

          <div className={styles.inputsSubSection}>
            <WppInput value="Filled" labelConfig={{ text: 'Label' }}>
              <WppIconLockOn slot="icon-start" />
            </WppInput>
            <WppInput value="Hover" labelConfig={{ text: 'Label' }} className={styles.inputHover}>
              <WppIconLockOn slot="icon-start" />
            </WppInput>
            <WppInput value="" placeholder="Hint" labelConfig={{ text: 'Label' }}>
              <WppIconLockOn slot="icon-start" />
            </WppInput>
            <WppInput value="Active" labelConfig={{ text: 'Label' }} className={styles.inputActive}>
              <WppIconLockOn slot="icon-start" />
            </WppInput>
            <WppInput value="Disabled" labelConfig={{ text: 'Label' }}>
              <WppIconLockOn slot="icon-start" />
            </WppInput>
          </div>

          <div className={styles.inputsSubSection}>
            <WppInput value="Filled" labelConfig={{ text: 'Label' }} message="Warning message" messageType="warning">
              <WppIconLockOn slot="icon-start" />
            </WppInput>
            <WppInput
              value="Hover"
              labelConfig={{ text: 'Label' }}
              message="Warning message"
              messageType="warning"
              className={styles.inputHover}
            >
              <WppIconLockOn slot="icon-start" />
            </WppInput>
            <WppInput
              value=""
              placeholder="Hint"
              labelConfig={{ text: 'Label' }}
              message="Warning message"
              messageType="warning"
            >
              <WppIconLockOn slot="icon-start" />
            </WppInput>
          </div>

          <div className={styles.inputsSubSection}>
            <WppInput value="Filled" labelConfig={{ text: 'Label' }} message="Error message" messageType="error">
              <WppIconLockOn slot="icon-start" />
            </WppInput>
            <WppInput
              value="Hover"
              labelConfig={{ text: 'Label' }}
              message="Error message"
              messageType="error"
              className={styles.inputHover}
            >
              <WppIconLockOn slot="icon-start" />
            </WppInput>
            <WppInput
              value=""
              placeholder="Hint"
              labelConfig={{ text: 'Label' }}
              message="Error message"
              messageType="error"
            >
              <WppIconLockOn slot="icon-start" />
            </WppInput>
          </div>
        </div>
        <WppDivider className={styles.divider} />

        <div className={styles.section}>
          <WppTypography type="xl-heading" className={styles.sectionLabel}>
            Checkbox
          </WppTypography>

          <div className={styles.checkboxSubSection}>
            <WppCheckbox labelConfig={{ text: 'Idle' }} />
            <WppCheckbox labelConfig={{ text: 'Hover' }} className={styles.checkboxHover} />
            <WppCheckbox labelConfig={{ text: 'Pressed' }} className={styles.checkboxActive} />
            <WppCheckbox labelConfig={{ text: 'Disabled' }} disabled />
          </div>
          <div className={styles.checkboxSubSection}>
            <WppCheckbox checked labelConfig={{ text: 'Selected Idle' }} />
            <WppCheckbox checked labelConfig={{ text: 'Selected Hover' }} className={styles.checkboxSelectedHover} />
            <WppCheckbox checked labelConfig={{ text: 'Selected Pressed' }} className={styles.checkboxSelectedActive} />
            <WppCheckbox checked labelConfig={{ text: 'Selected Disabled' }} disabled />
          </div>
        </div>
        <WppDivider className={styles.divider} />

        <div className={styles.section}>
          <WppTypography type="xl-heading" className={styles.sectionLabel}>
            Pills
          </WppTypography>

          <div className={styles.pillsSubSection}>
            <WppPill>
              <WppIconFavorites slot="icon-start" />
              Idle
            </WppPill>
            <WppPill className={styles.pillsHover}>
              <WppIconFavorites slot="icon-start" style={{ color: 'var(--wpp-icon-color-hover)' }} />
              Hover
            </WppPill>
            <WppPill className={styles.pillsActive}>
              <WppIconFavorites slot="icon-start" style={{ color: 'var(--wpp-icon-color-active)' }} />
              Pressed
            </WppPill>
            <WppPill checked>
              <WppIconFavorites slot="icon-start" style={{ color: 'var(--wpp-primary-color-600)' }} />
              Selected
            </WppPill>
            <WppPill checked className={styles.pillsSelectedHover}>
              <WppIconFavorites slot="icon-start" style={{ color: 'var(--wpp-primary-color-600)' }} />
              Selected Hover
            </WppPill>
            <WppPill disabled>
              <WppIconFavorites slot="icon-start" />
              Disabled
            </WppPill>
          </div>
        </div>
        <WppDivider className={styles.divider} />

        <div className={styles.section}>
          <WppTypography type="xl-heading" className={styles.sectionLabel}>
            Regular Card
          </WppTypography>

          <div className={styles.cardsSubSection}>
            <WppCard>
              <CardPreview title="Title" />
            </WppCard>
            <WppCard variant="secondary">
              <CardPreview title="Title" />
            </WppCard>
          </div>
        </div>

        <div className={styles.section}>
          <WppTypography type="xl-heading" className={styles.sectionLabel}>
            Clickable Card
          </WppTypography>

          <div className={styles.cardsSubSection}>
            <WppCard interactive>
              <CardPreview title="Idle" />
            </WppCard>
            <WppCard interactive className={styles.clickableCardHover}>
              <CardPreview title="Hover" />
            </WppCard>
            <WppCard interactive className={styles.clickableCardActive}>
              <CardPreview title="Pressed" />
            </WppCard>
          </div>
        </div>

        <div className={styles.section}>
          <WppTypography type="xl-heading" className={styles.sectionLabel}>
            Selectable Card
          </WppTypography>

          <WppCardGroup withRadioOrCheckbox className={styles.cardsSubSection} style={{ marginBottom: 24 }}>
            <WppCard type="single" checked={false}>
              <CardPreview title="Idle" withActionButton={false} />
            </WppCard>
            <WppCard type="single" checked={false} className={styles.selectableCardHover}>
              <CardPreview title="Hover" withActionButton={false} />
            </WppCard>
            <WppCard type="single" checked={false} className={styles.selectableCardActive}>
              <CardPreview title="Pressed" withActionButton={false} />
            </WppCard>
            <WppCard type="single" checked={false} disabled>
              <CardPreview title="Disabled" withActionButton={false} />
            </WppCard>
          </WppCardGroup>

          <WppCardGroup withRadioOrCheckbox className={styles.cardsSubSection}>
            <WppCard type="single" checked>
              <CardPreview title="Selected Idle" withActionButton={false} />
            </WppCard>
            <WppCard type="single" checked>
              <CardPreview
                title="Selected Hover"
                withActionButton={false}
                className={styles.selectableCardSelectedHover}
              />
            </WppCard>
            <WppCard type="single" checked>
              <CardPreview
                title="Selected Pressed"
                withActionButton={false}
                className={styles.selectableCardSelectedActive}
              />
            </WppCard>
            <WppCard type="single" checked disabled>
              <CardPreview title="Selected Disabled" withActionButton={false} />
            </WppCard>
          </WppCardGroup>
        </div>
      </div>
    </div>
  </div>
)
