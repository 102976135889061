import React from 'react'

import {
  WppActionButton,
  WppButton,
  WppFileUpload,
  WppIconChevron,
  WppListItem,
  WppSelect,
  WppStep,
  WppStepper,
  WppTextareaInput,
  WppTopbar,
  WppTypography,
} from '@platform-ui-kit/components-library-react'

import styles from './FormPreview.module.scss'
import { formPreviewNavigation, PAGE_PREVIEW_ID } from '../consts'

const selectOne = [
  'Commerce Director',
  'Augmented Reality',
  'Commerce Director1',
  'Augmented Reality1',
  'Commerce Director2',
  'Augmented Reality2',
  'Commerce Director3',
  'Augmented Reality3',
]
const selectTwo = [
  'BAV Brand Data',
  'Voice Assistant',
  'AmeriLINK Data1',
  'BAV Brand Data1',
  'Voice Assistant2',
  'AmeriLINK Data2',
  'Voice Assistant3',
  'AmeriLINK Data3',
]

export const FormPreview = () => (
  <div className={styles.root}>
    <div id={PAGE_PREVIEW_ID}>
      <WppTopbar
        value={formPreviewNavigation[1].value}
        navigation={formPreviewNavigation}
        className={styles.previewHeader}
      />

      <div className={styles.main}>
        <div>
          <WppActionButton variant="secondary">
            <WppIconChevron direction="left" slot="icon-start" style={{ color: 'var(--wpp-grey-color-800)' }} />
            <WppTypography type="s-body" style={{ color: 'var(--wpp-grey-color-800)' }}>
              Back
            </WppTypography>
          </WppActionButton>
        </div>

        <div className={styles.titleWrapper}>
          <WppTypography type="3xl-heading">Create new product</WppTypography>

          <div className={styles.buttonsWrapper}>
            <WppActionButton>Cancel</WppActionButton>
            <WppButton variant="secondary">Back</WppButton>
            <WppButton>Next</WppButton>
          </div>
        </div>

        <div className={styles.wrapper}>
          <div className={styles.fullWidthStepper}>
            <WppStepper activeStep={4} orientation="horizontal" className={styles.stepper}>
              {['General', 'Client', 'Market', 'Summary', 'Problem solved', 'Review and save'].map(step => (
                <WppStep key={`step-item-${step}`}>
                  <p slot="label" className={styles.text}>
                    {step}
                  </p>
                </WppStep>
              ))}
            </WppStepper>
          </div>

          <div className={styles.page}>
            <WppTypography type="l-strong" style={{ marginBottom: 20 }}>
              Summary
            </WppTypography>

            <WppTextareaInput
              value="Evaluate how people buy products and services – See the biases and motivations that influence purchase behaviour across 50 territories.  Review activity in your client’s category –  Assess the behaviours driving consumers in your client’s category, from beverages to baby care."
              required
              charactersLimit={400}
              labelConfig={{ text: 'Product description' }}
              style={{ marginBottom: 32 }}
            />

            <WppFileUpload>
              <WppTypography type="m-strong" slot="label" style={{ marginTop: 32, marginBottom: 16 }}>
                Product video
              </WppTypography>
            </WppFileUpload>

            <WppTypography type="m-strong" style={{ marginTop: 32, marginBottom: 16 }}>
              Linked products
            </WppTypography>

            <div className={styles.selectWrapper}>
              <WppSelect value={selectOne.slice(0, 4)} labelConfig={{ text: 'Applications' }} type="multiple">
                {selectOne.map(label => (
                  <WppListItem key={label} value={label} multiple>
                    <p slot="label">{label}</p>
                  </WppListItem>
                ))}
              </WppSelect>
              <WppSelect value={selectTwo.slice(0, 5)} labelConfig={{ text: 'Datasets' }} type="multiple">
                {selectTwo.map(label => (
                  <WppListItem key={label} value={label} multiple>
                    <p slot="label">{label}</p>
                  </WppListItem>
                ))}
              </WppSelect>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
