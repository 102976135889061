import React from 'react'

import { WppActionButton, WppIconMore, WppTypography } from '@platform-ui-kit/components-library-react'

import styles from './CardPreview.module.scss'

export const CardPreview = ({ title, withActionButton = true }: any) => (
  <>
    <div className={styles.content} />
    <div className={styles.header} slot="header">
      <WppTypography className={styles.title}>{title}</WppTypography>
    </div>

    {withActionButton && (
      <WppActionButton variant="secondary" slot="actions">
        <WppIconMore slot="icon-start" direction="horizontal" />
      </WppActionButton>
    )}
  </>
)
